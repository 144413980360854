<template>
    <v-navigation-drawer
        :value="view_prices"          
        width="450"
        app
        temporary
        right
        class="wost-drawer"
        @input="handleViewPrices"
    >
        <div class="navigation-head">
            <span>Precios y descuentos</span>                         
        </div>

        <div class="pl-3 pt-3 pr-3" style="height: 80vh;" v-if="item_product">
            <template>
                <v-row>
                    <v-col cols="12">
                        <v-card>
                            <v-card-title class="pt-2 pb-2">
                                <span class="subtitle-2 font-weight-bold">{{ item_product.description }}</span>
                            </v-card-title>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row v-if="worker == null">
                    <v-col cols="12">
                        <v-card>
                            <v-card-title class="pt-1 pb-1">
                                <span class="subtitle-2 font-weight-bold">Trabajador</span>
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-card-text class="pt-4 pb-4">
                                <v-autocomplete                             
                                    v-model="worker_id"
                                    :items="workers"
                                    label="Seleccione un trabajador"
                                    hide-details
                                    outlined
                                    :item-text="customTextWorkers"
                                    item-value="id"
                                    clearable
                                ></v-autocomplete>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row v-if="item_product && item_product.type_item == 'product'">
                    <v-col cols="12">
                        <v-card>
                            <v-card-title class="pt-1 pb-1">
                                <span class="subtitle-2 font-weight-bold">Lista de precios</span>
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-card-text class="pt-1 pb-1">
                                <v-radio-group v-model="price_selected" dense>                                    
                                    <v-radio
                                        v-for="price in prices"
                                        :key="price.id"
                                        :value="price.price"
                                        :label="price.price | currency('S/')"
                                    >
                                    </v-radio>
                                </v-radio-group>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-card>
                            <v-card-title class="pt-1 pb-1">
                                <span class="subtitle-2 font-weight-bold">Descuento</span>
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-card-text>
                                <v-container class="pt-0 pb-0">
                                    <v-row>
                                        <v-col cols="4">
                                            <v-btn-toggle
                                                v-model="discount_type"
                                                mandatory
                                                color="green darken-3"
                                                class="mr-3">
                                                <v-btn value="Monto">
                                                    S/ 
                                                </v-btn>
                                                <v-btn value="Porcentaje">
                                                    %
                                                </v-btn>
                                            </v-btn-toggle>
                                        </v-col>
                                        <v-col cols="8">
                                            <v-text-field :label="`${discount_type} a descontar`" 
                                                class="field-discount" 
                                                v-model="discount_amount"
                                                outlined
                                                hide-details="auto"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row v-if="item_product.is_bonus">
                    <v-col cols="12">
                        <v-card>
                            <v-card-title class="pt-1 pb-1">
                                <span class="subtitle-2 font-weight-bold">Nota</span>
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-card-text class="pt-3 pb-3">
                                <v-textarea v-model="note"
                                    outlined
                                    rows="3"
                                    hide-details="auto">
                                </v-textarea>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </template>
        </div>

        <div class="mt-3 pl-3 pt-3 pr-3">
            <template>
                <v-row>
                    <v-col cols="6">
                        <v-btn block height="40" dark color="red" @click="handleCancel">
                            <v-icon class="mr-1" size="16">far fa-times-circle</v-icon>
                            Cancelar
                        </v-btn>
                    </v-col>
                    <v-col cols="6">
                        <v-btn dark color="success" block height="40" @click="handleStore">
                            <v-icon class="mr-1" size="16">far fa-save</v-icon>
                            Guardar
                        </v-btn>
                    </v-col>
                </v-row>
            </template>               
            
        </div>

    </v-navigation-drawer>
</template>

<script>
import {mapState, mapActions} from 'vuex';

export default {
    name: 'Prices',
    data () {
        return {
            item_product: null,
            price_selected: null,            
            discount_type: null,
            discount_amount: null,
            worket_id: null,
            note: null
        }
    },
    computed: {
        ...mapState('employees', [
            'workers'
        ]),
        ...mapState('sales', [
            'view_prices',
            'product',
            'worker'
        ]),
        ...mapState('products', [
            'prices'
        ])
    },
    methods: {
        ...mapActions('sales', [
            'setViewPrices',
            'select_product',
            'add_product_to_cart',
            'replace_product_cart'
        ]),
        ...mapActions('products', [
            'getPrices',
            'empty_prices'
        ]),
        async handleViewPrices(value){
            this.clearData();           
            if (value == true) {
                this.item_product = Object.assign({}, this.product);
                this.worker_id = this.item_product.worker_id;
                this.price_selected = this.item_product.price;
                this.note = this.item_product.note;
                if (this.item_product.discount_type) {
                    this.discount_type = this.item_product.discount_type;
                }
                if (this.item_product.discount_amount) {
                    this.discount_amount = this.item_product.discount_amount;
                }
                if (this.item_product.type_item == 'product') {
                    await this.getPrices(this.product);
                }                                
            }
            this.setViewPrices(value);
        },
        clearData () {
            this.empty_prices();
            this.item_product = null;
            this.price_selected = null;
            this.discount_type = null;
            this.discount_amount = null;
            this.worket_id = null;
            this.note = null;
        },
        closeForm() {
            this.empty_prices();
            this.select_product(null);
            this.setViewPrices(false);
        },
        customTextWorkers (item) {
            let text = item.fullname
            
            return text
        },
        handleCancel() {
            this.closeForm();
        },
        handleStore() {
            this.item_product.worker_id = this.worker_id;
            this.item_product.discount_type = this.discount_type;
            this.item_product.discount_amount = this.discount_amount;
            this.item_product.note = this.note;
            if (this.price_selected) {
                this.item_product.price = this.price_selected;
            }
            this.item_product.subtotal = parseFloat(this.item_product.quantity) * parseFloat(this.item_product.price);
            this.calculateDiscountAmount();

            if (this.item_product.discount_value >= this.item_product.subtotal) {
                this.$swal({
                    text: "El descuento debe ser menor al total del item",
                    icon: "error",
                    showCancelButton: false,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Aceptar"
                });
            } else {
                let newItem = Object.assign({}, this.item_product);
                this.replace_product_cart(newItem);
                this.closeForm();
            }
        },
        calculateDiscountAmount () {
            if (this.item_product.discount_type) {
                let amount_discount = parseFloat(this.item_product.discount_amount);
                if (this.item_product.discount_type == 'Porcentaje') {
                    let percentage = parseFloat(this.item_product.discount_amount);
                    let discount = parseFloat(this.item_product.subtotal) * (percentage / 100);
                    amount_discount = Math.round(discount * 100) / 100;
                }
                this.item_product.discount_value = Math.round(amount_discount * 100) / 100;
            }
        }
    }
}
</script>