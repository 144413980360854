<template>
    <v-navigation-drawer
        v-model="drawer"          
        width="980"
        app
        temporary
        right
        class="wost-drawer"
    >
        <div class="navigation-head navigation-head-cart">
            <template>
                <v-row>
                    <v-col cols="6" class="pt-2 pb-2">
                        <span>REGISTRO DE PAGO DE VENTA</span>
                    </v-col>
                    <v-col cols="6" class="pt-0 pb-2">
                        <v-btn-toggle
                            v-model="type_document"
                            mandatory
                            dense
                            color="green darken-3"
                            class="float-right">
                            <v-btn value="TICKET" class="btn-show-mode">
                                TICKET 
                            </v-btn>
                            <v-btn :value="type_document_customer" class="btn-show-mode">
                                {{ type_document_customer }}
                            </v-btn>
                        </v-btn-toggle>
                    </v-col>
                </v-row>
            </template>
        </div>

        <div class="pl-3 pr-3">
            <template>
                <v-row>
                    <v-col cols="6">
                        <v-card class="detail-sale" style="height: 100% !important">
                            <v-card-title class="pt-1 pb-1">
                                <span v-if="type_operation === 'sale'" class="subtitle-2 font-weight-bold">
                                    Detalle de venta
                                </span>
                                <span v-else class="subtitle-2 font-weight-bold">
                                    Detalle de pedido
                                </span>
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-card-text v-if="type_operation === 'order'"
                                class="pt-1 pl-0 pr-0 pb-1">
                                <v-container class="pt-0 pb-1">
                                    <v-row>
                                        <v-col cols="12" class="pb-1">
                                            <v-menu
                                                v-model="menuOrder"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="290px"
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                        v-model="date_delivery"
                                                        label="Fecha de entrega"
                                                        readonly
                                                        clearable
                                                        v-on="on"
                                                        outlined
                                                        hide-details
                                                        prepend-icon="far fa-calendar-alt"                       
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker v-model="date_delivery" @input="menuOrder = false" no-title>
                                                </v-date-picker>
                                            </v-menu>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-text class="pt-0 pl-0 pr-0 pb-0 section-list-items-cart">
                                <v-container class="pt-0">
                                    <v-row>
                                        <v-col cols="12" class="pt-1 pb-1 pl-0 pr-0">
                                            <v-list dense two-line class="list-cart-items pt-0 pb-0">
                                                <v-list-item v-for="product in cart" :key="product.id" class="cart-item">
                                                    <!--
                                                    <v-list-item-icon class="mr-3 mb-0">
                                                        <span class="cart-item-quantity">{{ product.quantity }}</span>
                                                    </v-list-item-icon>
                                                    -->
                                                    <v-list-item-content class="pt-0 pb-0">
                                                        <v-list-item-title>
                                                            <span class="text-uppercase font-weight-bold">
                                                                {{ product.description }}
                                                            </span>
                                                        </v-list-item-title>
                                                        <v-list-item-subtitle>
                                                            <span class="cart-item-quantity">
                                                                {{ product.quantity }}
                                                            </span>
                                                        </v-list-item-subtitle>
                                                    </v-list-item-content>

                                                    <v-list-item-action class="mb-1 cart-item-action">
                                                        <v-list-item-action-text>
                                                            <span v-if="product.discount_value" class="font-weight-bold mr-2">
                                                                {{ getAmountProductDiscount(product) | currency('S/') }}
                                                            </span>
                                                            <span v-else class="font-weight-bold mr-2">{{ product.subtotal | currency('S/') }}</span>
                                                            <v-icon v-if="product.is_bonus"
                                                                size="18" 
                                                                class="ml-2"
                                                                color="green">
                                                                fas fa-gift
                                                            </v-icon>
                                                        </v-list-item-action-text>
                                                        <v-list-item-action-text v-if="product.discount_value">
                                                            <span class="label-amount-original label-price-discounted">
                                                                {{ product.subtotal | currency('S/') }}
                                                            </span>
                                                        </v-list-item-action-text>
                                                    </v-list-item-action>
                                                </v-list-item>
                                            </v-list>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-actions class="pt-2 pl-0 pr-0 pb-1">
                                <v-container class="pt-1 pb-1">
                                    <v-row>
                                        <v-col cols="12" class="pl-0 pr-0 pb-0">
                                            <v-list class="pt-0">

                                                <v-list-item class="amount-summary">                                
                                                    <v-list-item-content class="pt-1 pb-1">
                                                        <v-list-item-title>
                                                            <span class="font-weight-bold label-discount label-amount-sales">DESCUENTO</span>
                                                        </v-list-item-title>
                                                    </v-list-item-content>

                                                    <v-list-item-action class="mt-1 mb-1">
                                                        <span class="font-weight-bold label-discount label-amount-sales">{{ discount | currency('S/') }}</span>
                                                    </v-list-item-action>
                                                </v-list-item>
                                                <v-divider></v-divider>

                                                <v-list-item class="amount-summary">                                
                                                    <v-list-item-content class="pt-1 pb-1">
                                                        <v-list-item-title>
                                                            <span class="font-weight-bold label-amount-sales">SUBTOTAL</span>
                                                        </v-list-item-title>
                                                    </v-list-item-content>

                                                    <v-list-item-action class="mt-1 mb-1">
                                                        <span class="font-weight-bold label-amount-sales">{{ subtotal | currency('S/') }}</span>
                                                    </v-list-item-action>
                                                </v-list-item>
                                                <v-divider></v-divider>

                                                <v-list-item class="amount-summary">
                                                    <v-list-item-content class="pt-1 pb-1">
                                                        <v-list-item-title>
                                                            <span class="font-weight-bold label-amount-sales">IGV</span>
                                                        </v-list-item-title>
                                                    </v-list-item-content>

                                                    <v-list-item-action class="mt-1 mb-1">
                                                        <span class="font-weight-bold label-amount-sales">{{ igv | currency('S/') }}</span>
                                                    </v-list-item-action>
                                                </v-list-item>
                                                <v-divider></v-divider>

                                                <v-list-item class="amount-summary">
                                                    <v-list-item-content class="pt-1 pb-1">
                                                        <v-list-item-title>
                                                            <span class="font-weight-bold label-amount-sales">REDONDEO</span>
                                                        </v-list-item-title>
                                                    </v-list-item-content>

                                                    <v-list-item-action class="mt-1 mb-1">
                                                        <span class="font-weight-bold label-amount-sales">{{ rounding | currency('S/') }}</span>
                                                    </v-list-item-action>
                                                </v-list-item>
                                                <v-divider></v-divider>

                                                <v-list-item class="amount-summary">
                                                    <v-list-item-content class="pt-1 pb-1">
                                                        <v-list-item-title>
                                                            <span class="font-weight-bold label-amount-sales">TOTAL</span>
                                                        </v-list-item-title>
                                                    </v-list-item-content>

                                                    <v-list-item-action class="mt-1 mb-1">
                                                        <span class="font-weight-bold label-amount-sales">{{ total_rounded | currency('S/') }}</span>
                                                    </v-list-item-action>
                                                </v-list-item>
                                            </v-list>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-actions>
                        </v-card>
                    </v-col>

                    <v-col cols="6">
                        <v-card style="height: 100% !important">
                            <v-card-title class="pt-1 pb-1">
                                <span class="subtitle-2 font-weight-bold">Detalle de pago</span>
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-card-text class="detail-payment">
                                <v-container class="pt-0">
                                    <v-row v-if="credit_sale == false" justify="center">                                        
                                        <v-col cols="3">
                                            <v-btn small dark color="green" block @click="handleShowPayMethod('CASH')">
                                                EFECTIVO
                                            </v-btn>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-btn small dark color="indigo darken-4" block @click="handleShowPayMethod('TRANSFERENCIA')">
                                                TRANSFERENCIA
                                            </v-btn>
                                        </v-col>
                                        <v-col cols="3">
                                            <v-btn small dark color="#ff9900" block @click="handleShowPayMethod('TARJETA')">
                                                TARJETA
                                            </v-btn>
                                        </v-col>
                                        <v-col cols="2">
                                            <v-btn small dark color="#742284" block @click="handleShowPayMethod('YAPE')">
                                                YAPE
                                            </v-btn>
                                        </v-col>                                        
                                    </v-row>

                                    <v-row v-if="pay_cash">
                                        <v-col cols="8" class="pt-2 text-right" >
                                            <span class="title font-weight-medium item-amount">EFECTIVO</span>
                                        </v-col>
                                        <v-col cols="4" class="pt-0">
                                            <v-text-field 
                                                :value="amount_cash" 
                                                prefix="S/"
                                                placeholder="0"
                                                outlined 
                                                hide-details
                                                type="number"
                                                @input="handleInputAmountCash">
                                            </v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-row v-if="pay_visa">
                                        <v-col cols="8" class="pt-2 text-right" >
                                            <span class="title font-weight-medium item-amount">VISA</span>
                                        </v-col>
                                        <v-col cols="4" class="pt-0">
                                            <v-text-field 
                                                v-model="amount_visa" 
                                                prefix="S/"
                                                placeholder="0"
                                                outlined 
                                                hide-details
                                                type="number"
                                                @input="handleInputAmountVisa">
                                            </v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-row v-if="pay_master">
                                        <v-col cols="8" class="pt-2 text-right" >
                                            <span class="title font-weight-medium item-amount">MASTERCARD</span>
                                        </v-col>
                                        <v-col cols="4" class="pt-0">
                                            <v-text-field 
                                                v-model="amount_master" 
                                                prefix="S/"
                                                placeholder="0"
                                                outlined 
                                                hide-details
                                                type="number"
                                                @input="handleInputAmountMaster">
                                            </v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-row v-if="pay_american">
                                        <v-col cols="8" class="pt-2 text-right" >
                                            <span class="title font-weight-medium item-amount">AMERICAN EXPRESS</span>
                                        </v-col>
                                        <v-col cols="4" class="pt-0">
                                            <v-text-field 
                                                v-model="amount_american" 
                                                prefix="S/"
                                                placeholder="0"
                                                outlined 
                                                hide-details
                                                type="number"
                                                @input="handleInputAmountAmerican">
                                            </v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-row v-if="pay_transfer">
                                        <v-col cols="8" class="pt-1 text-right" >
                                            <!-- <span class="title font-weight-medium item-amount">TRANSFERENCIA</span> -->
                                            <v-autocomplete
                                                v-model="bank_account_id"
                                                :items="accounts"
                                                outlined
                                                label="Cuenta Bancaria"
                                                persistent-hint
                                                :item-text="customTextAccount"
                                                item-value="id"
                                                hide-details
                                                :filter="customFilterAccount"
                                            >
                                                <template v-slot:item="data">
                                                <v-list-item-content>
                                                    <v-list-item-title>{{ data.item.bank.name }}</v-list-item-title>
                                                    <v-list-item-subtitle>{{ data.item.type }}: {{ data.item.number_account }}</v-list-item-subtitle>
                                                </v-list-item-content>
                                                </template>
                                            </v-autocomplete>
                                        </v-col>
                                        <v-col cols="4" class="pt-1">
                                            <v-text-field 
                                                v-model="amount_transfer" 
                                                prefix="S/"
                                                placeholder="0"
                                                outlined 
                                                hide-details
                                                type="number"
                                                @input="handleInputAmountTransfer">
                                            </v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-row v-if="pay_card">
                                        <v-col cols="8" class="pt-2 text-right" >
                                            <!-- <span class="title font-weight-medium item-amount">TARJETA</span> -->
                                            <v-autocomplete
                                                v-model="bank_account_id"
                                                :items="accounts"
                                                outlined
                                                label="Cuenta Bancaria"
                                                persistent-hint
                                                :item-text="customTextAccount"
                                                item-value="id"
                                                hide-details
                                                :filter="customFilterAccount"
                                                readonly
                                            >
                                                <template v-slot:item="data">
                                                <v-list-item-content>
                                                    <v-list-item-title>{{ data.item.bank.name }}</v-list-item-title>
                                                    <v-list-item-subtitle>{{ data.item.type }}: {{ data.item.number_account }}</v-list-item-subtitle>
                                                </v-list-item-content>
                                                </template>
                                            </v-autocomplete>
                                        </v-col>
                                        <v-col cols="4" class="pt-2">
                                            <v-text-field 
                                                v-model="amount_card" 
                                                prefix="S/"
                                                placeholder="0"
                                                outlined 
                                                hide-details
                                                type="number"
                                                @input="handleInputAmountCard">
                                            </v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-row v-if="pay_yape">
                                        <v-col cols="8" class="pt-2 text-right" >
                                            <span class="title font-weight-medium item-amount">YAPE</span>
                                        </v-col>
                                        <v-col cols="4" class="pt-0">
                                            <v-text-field 
                                                v-model="amount_yape" 
                                                prefix="S/"
                                                placeholder="0"
                                                outlined 
                                                hide-details
                                                type="number"
                                                @input="handleInputAmountYape">
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    
                                    <v-divider v-if="has_credit == true"></v-divider>
                                    <v-row v-if="has_credit == true">
                                        <v-col cols="6">
                                            <span class="font-weight-bold">
                                                CRÉDITO
                                            </span>   
                                        </v-col>
                                        <v-col cols="6">
                                            <span class="float-right font-weight-bold">
                                                {{ credit_amount | currency('S/') }}
                                            </span>                                            
                                        </v-col>
                                    </v-row>
                                    <v-divider v-if="has_credit == true"></v-divider>
                                    <v-row v-if="has_credit == true">
                                        <v-col cols="6">
                                            <v-switch 
                                                v-model="credit_sale" 
                                                class="mt-0" 
                                                label="VENTA AL CRÉDITO"
                                                hide-details
                                                :disabled="cant_apply_credit"
                                                @change="handleChangeCreditSale"
                                            ></v-switch>
                                        </v-col>
                                        <v-col cols="6" v-if="credit_sale">
                                            <v-menu
                                                v-model="menuDatePayment"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="290px"
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                    v-model="date_payment"
                                                    label="Fecha de pago"
                                                    readonly
                                                    clearable
                                                    outlined
                                                    dense
                                                    v-on="on"
                                                    hide-details                                    
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker v-model="date_payment" 
                                                    @input="menuDatePayment = false" 
                                                    no-title
                                                    locale="es"
                                                    :min="date_payment_min">
                                                </v-date-picker>
                                            </v-menu>
                                        </v-col>
                                    </v-row>
                                    <v-divider v-if="has_credit == true"></v-divider>
                                    <v-row v-if="credit_sale == true">
                                        <v-col cols="12">
                                            <v-switch 
                                                v-model="generate_voucher" 
                                                class="mt-0" 
                                                label="GENERAR COMPROBANTE DE VENTA"
                                                hide-details
                                            ></v-switch>
                                        </v-col>
                                    </v-row>
                                    <v-divider></v-divider>
                                    <v-row>
                                        <v-col cols="6">
                                            <span class="font-weight-bold">DEUDA TOTAL</span>
                                        </v-col>
                                        <v-col cols="6">
                                            <span class="float-right font-weight-bold">{{ total_rounded | currency('S/') }}</span>                                            
                                        </v-col>
                                    </v-row>
                                    <v-divider></v-divider>
                                    <v-row v-if="amount_debt > 0">
                                        <v-col cols="6">
                                            <span class="font-weight-bold amount-missing">FALTANTE</span>
                                        </v-col>
                                        <v-col cols="6">
                                            <span class="float-right font-weight-bold amount-missing">{{ amount_debt | currency('S/') }}</span>                                            
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="amount_change > 0">
                                        <v-col cols="6">
                                            <span class="font-weight-bold amount-change">Vuelto</span>
                                        </v-col>
                                        <v-col cols="6">
                                            <span class="float-right font-weight-bold amount-change">{{ amount_change | currency('S/') }}</span>                                            
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions>
                                <v-container>
                                    <v-row>
                                        <v-col cols="6">
                                            <v-btn color="red" dark block height="49" @click="handleCancelPayment">
                                                Cancelar
                                            </v-btn>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-btn color="green" 
                                                dark 
                                                block 
                                                height="49" 
                                                @click="handleRegisterSale"
                                                :disabled="press_button">
                                                Pagar
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
            </template>
        </div>

        <v-snackbar v-model="cant_pay" top color="error">
            {{ message_validation }}
            <v-btn dark text @click="handleCloseMessageValidation">
                Cerrar
            </v-btn>
        </v-snackbar>

    </v-navigation-drawer>
</template>

<script>
import {mapState, mapActions} from 'vuex';

import NumberTools from '../../helpers/NumberTools';
import printUtil from '@/plugins/print';

import TicketTools from '../../helpers/TicketTools';

import JQuery from 'jquery';
let $ = JQuery;

export default {
    name: 'Payment',
    data() {
        return {
            drawer: false,
            titleForm: null,
            message_validation: null,
            cant_pay: false,
            pay_cash: false,
            pay_visa: false,
            pay_master: false,
            pay_american: false,
            pay_yape: false,
            pay_transfer: false,
            pay_card: false,
            amount_cash: null,
            amount_visa: null,
            amount_master: null,
            amount_american: null,
            amount_yape: null,
            amount_transfer: null,
            amount_card: null,
            amount_debt: 0,
            amount_change: 0,
            type_document: 'TICKET',
            menuOrder: false,
            date_delivery: null,
            type_document_customer: null,
            has_credit: false,
            credit_amount: null,            
            cant_apply_credit: false,
            credit_sale: false,
            date_payment: null,
            date_payment_min: new Date().toISOString().substr(0, 10),
            menuDatePayment: false,
            generate_voucher: false,
            press_button: false,
            bank_account_id: null,
            validate_amount_payment: false
        }
    },
    computed: {
        ...mapState('authentication', [
            'office_id',
        ]),
        ...mapState('customers', [
            'customers'
        ]),
        ...mapState('sales', [
            'customer',
            'cart',
            'subtotal',
            'igv',
            'total',
            'sale',
            'discount',
            'type_operation',
            'date_invoice',
            'rounding',
            'total_rounded',
            'total_gratuitas',
            'sale_station',
            'worker_id'
        ]),
        ...mapState('tills', [
            'selected_till'
        ]),
        ...mapState('accounts', [
            'accounts',
            'account_pay_card'
        ])
    },
    methods: {
        ...mapActions('sales', [
            'storeSale',
            'setDateInvoice',
            'storePaymentByStation'
        ]),
        ...mapActions('inventories', [
            'run_set_catalogue',
            'getCatalogueToSale'
        ]),
        handleCloseMessageValidation() {
            this.cant_pay = false;
            this.message_validation = null;
        },
        showForm(title) {
            this.titleForm = title;
            this.amount_debt = this.total_rounded;
            this.amount_change = 0;
            if (this.customer) {
                this.setTypeDocumentSale(this.customer);
            }
            this.drawer = !this.drawer;
        },
        setTypeDocumentSale(customer) {
            if (customer) {
                if (customer.type_document.name == 'RUC') {
                    this.type_document_customer = 'FACTURA';
                } else {
                    this.type_document_customer = 'BOLETA';
                }
                if (customer.credit_amount) {
                    let amount = parseFloat(customer.credit_amount);
                    if (amount > 0) {
                        this.credit_amount = amount;
                        this.has_credit = true;

                        if (this.total > amount) {
                            this.cant_apply_credit = true
                        }
                    }
                }
            }
        },
        handleChangeCreditSale (value) {
            if (value == false) {
                this.date_payment = null;
                this.generate_voucher = false;
            }
        },
        clearData() {
            this.pay_cash = false;
            this.pay_visa = false;
            this.pay_master = false;
            this.pay_american = false;
            this.pay_yape = false;
            this.pay_transfer = false;
            this.pay_card = false;
            this.amount_cash = null;
            this.amount_visa = null;
            this.amount_master = null;
            this.amount_american = null;
            this.amount_yape = null;
            this.amount_transfer = null;
            this.amount_card = null;
            this.amount_debt = 0;
            this.amount_change = 0;
            this.has_credit = false;
            this.credit_amount = null;
            this.credit_sale = false;
            this.date_payment = null;
            this.cant_apply_credit = false;
            this.generate_voucher = false;
            this.type_document = 'TICKET';
            this.bank_account_id = null;
            this.validate_amount_payment = false;    
        },
        handleShowPayMethod(method){
            if (method == 'CASH') {
                this.amount_cash = null;             
                this.pay_cash = !this.pay_cash;
            } else if (method == 'VISA') {
                this.amount_visa = null;             
                this.pay_visa = !this.pay_visa;
            } else if (method == 'MASTERCARD') {
                this.amount_master = null;             
                this.pay_master = !this.pay_master;
            } else if (method == 'AMERICAN') {
                this.amount_american = null;             
                this.pay_american = !this.pay_american;
            } else if (method == 'YAPE') {
                this.amount_yape = null;             
                this.pay_yape = !this.pay_yape;
            } else if (method == 'TRANSFERENCIA') {
                this.bank_account_id = null;
                this.amount_transfer = null;             
                this.pay_transfer = !this.pay_transfer;
            } else if (method == 'TARJETA') {
                this.bank_account_id = null;
                this.amount_card = null;             
                this.pay_card = !this.pay_card;
                if (this.pay_card) {
                    if (this.account_pay_card) {
                        this.bank_account_id = this.account_pay_card.id;
                    }
                }
            }
            this.calculateChange();
        },
        calculateChange() {
            let value_cash = NumberTools.getNumberDecimalValue(this.amount_cash);
            let value_visa = NumberTools.getNumberDecimalValue(this.amount_visa);
            let value_mastercard = NumberTools.getNumberDecimalValue(this.amount_master);
            let value_american = NumberTools.getNumberDecimalValue(this.amount_american);
            let value_yape = NumberTools.getNumberDecimalValue(this.amount_yape);
            let value_transfer = NumberTools.getNumberDecimalValue(this.amount_transfer);
            let value_card = NumberTools.getNumberDecimalValue(this.amount_card);

            if (value_yape > 0 || value_transfer > 0 || value_card > 0) {
                this.validate_amount_payment = true;
            } else {
                this.validate_amount_payment = false;
            }

            let sum = value_cash + value_visa + value_mastercard + value_american + value_yape + value_transfer + value_card;
            sum = Math.round(sum * 100) / 100;

            let debt = this.total_rounded;
            debt = Math.round(debt * 100) / 100;
            
            this.amount_change = 0;
            if (sum > debt) {
                let dif = sum - debt;
                this.amount_change = Math.round(dif * 100) / 100;
                this.amount_debt = 0;
            } else if(debt > sum) {
                let dif = debt - sum;
                this.amount_debt = Math.round(dif * 100) / 100;
            } else if(debt == sum) {
                this.amount_debt = 0;
            }
        },
        handleInputAmountCash(value) {
            this.amount_cash = value;
            this.calculateChange();
        },
        handleInputAmountVisa(value) {
            this.amount_visa = value;
            this.calculateChange();
        },
        handleInputAmountMaster(value) {
            this.amount_master = value;
            this.calculateChange();
        },
        handleInputAmountAmerican(value) {
            this.amount_american = value;
            this.calculateChange();
        },
        handleInputAmountYape(value) {
            this.amount_yape = value;
            this.calculateChange();
        },
        handleInputAmountTransfer(value) {
            this.amount_transfer = value;
            this.calculateChange();
        },
        handleInputAmountCard(value) {
            this.amount_card = value;
            this.calculateChange();
        },
        handleCancelPayment() {
            this.clearData();
            this.drawer = !this.drawer;
        },
        async handleRegisterSale() {
            this.press_button = true;
            let continue_process = true;
            if (this.credit_sale == true) {
                if (this.date_payment == null) {
                    this.press_button = false;
                    continue_process = false;
                    this.cant_pay = true;
                    this.message_validation = 'Debe ingresar fecha de pago.';
                }
            } else {
                if (this.amount_debt > 0) {
                    this.press_button = false;
                    continue_process = false;
                    this.cant_pay = true;
                    this.message_validation = 'Debe pagar el total de la venta.';
                } else {
                    if (this.pay_transfer === true) {
                        if (!this.bank_account_id) {
                            this.press_button = false;
                            continue_process = false;
                            this.cant_pay = true;
                            this.message_validation = 'Debe seleccionar la cuenta bancaria.';
                        } else if (this.validate_amount_payment) {
                            if (this.amount_change > 0) {
                                this.press_button = false;
                                continue_process = false;
                                this.cant_pay = true;
                                this.message_validation = 'El monto a pagar supera el total de la venta.';
                            }
                        }
                    } else if (this.validate_amount_payment) {
                        if (this.amount_change > 0) {
                            this.press_button = false;
                            continue_process = false;
                            this.cant_pay = true;
                            this.message_validation = 'El monto a pagar supera el total de la venta.';
                        }
                    }
                }
            }
            if (continue_process == true) {
                let sale_data = this.setEntitySale();
                if (this.sale_station == null) {
                    await this.storeSale(sale_data);                    
                } else {
                    await this.storePaymentByStation(sale_data);
                }
                this.searchProductInCatalogue();
                this.press_button = false;
                this.handleCancelPayment();
                if (sale_data.credit_sale == false || sale_data.generate_voucher == true) {
                    this.handlePrintVoucher(this.sale);
                }
            }
        },
        setEntitySale() {
            let payments = this.setListPayments();

            let sale = {
                office_id: this.office_id,
                id: this.sale_station,
                worker_id: this.worker_id,
                customer_id: this.customer.id,
                discount: this.discount,
                subtotal: this.subtotal,
                igv: this.igv,
                total: this.total,
                products: this.cart,
                payments: payments,
                till_id: this.selected_till.id,
                type_document: this.type_document,
                date_delivery: this.date_delivery,
                type_operation: this.type_operation,
                credit_sale: this.credit_sale,
                date_payment: this.date_payment,
                generate_voucher: this.generate_voucher,
                date_invoice: this.date_invoice,
                rounding: this.rounding,
                total_rounded: this.total_rounded,
                total_gratuitas: this.total_gratuitas
            }

            return sale;
        },
        setListPayments() {
            let payments = [];
            if (this.amount_cash && this.amount_cash > 0) {
                let item = {
                    method: "CASH",
                    amount_debt: this.total,
                    payment_amount: parseFloat(this.amount_cash),
                    amount_change: Math.round(this.amount_change * 100) / 100,
                    bank_account_id: null
                };
                payments.push(item);
            }

            if (this.amount_visa && this.amount_visa > 0) {
                let item = {
                    method: "VISA",
                    amount_debt: this.total,
                    payment_amount: parseFloat(this.amount_visa),
                    amount_change: 0,
                    bank_account_id: null
                };
                payments.push(item);
            }

            if (this.amount_master && this.amount_master > 0) {
                let item = {
                    method: "MASTERCARD",
                    amount_debt: this.total,
                    payment_amount: parseFloat(this.amount_master),
                    amount_change: 0,
                    bank_account_id: null
                };
                payments.push(item);
            }

            if (this.amount_american && this.amount_american > 0) {
                let item = {
                    method: "AMERICAN EXPRESS",
                    amount_debt: this.total,
                    payment_amount: parseFloat(this.amount_american),
                    amount_change: 0,
                    bank_account_id: null
                };
                payments.push(item);
            }

            if (this.amount_yape && this.amount_yape > 0) {
                let item = {
                    method: "YAPE",
                    amount_debt: this.total,
                    payment_amount: parseFloat(this.amount_yape),
                    amount_change: 0,
                    bank_account_id: null
                };
                payments.push(item);
            }

            if (this.amount_transfer && this.amount_transfer > 0) {
                let item = {
                    method: "TRANSFERENCIA",
                    amount_debt: this.total,
                    payment_amount: parseFloat(this.amount_transfer),
                    amount_change: 0,
                    bank_account_id: this.bank_account_id
                };
                payments.push(item);
            }

            if (this.amount_card && this.amount_card > 0) {
                let item = {
                    method: "TARJETA",
                    amount_debt: this.total,
                    payment_amount: parseFloat(this.amount_card),
                    amount_change: 0,
                    bank_account_id: this.bank_account_id
                };
                payments.push(item);
            }

            return payments;
        },
        async handlePrintVoucher (sale) {
            if (sale) {
                let content = await TicketTools.printTicketVenta(sale);
                this.printVoucher(content);
            }
            
        },
        printVoucher (content) {
            $('<iframe>', {name: 'voucheriframe',class: 'printFrame'})
            .appendTo('body')
            .contents().find('body')
            .append(content);

            window.frames['voucheriframe'].focus();

            setTimeout(() => {                 
                window.frames['voucheriframe'].print();
             }, 500);

            setTimeout(() => { $(".printFrame").remove(); }, 1000);
        },
        getAmountProductDiscount(product) {
            let amount = 0;
            let subtotal = Math.round(product.subtotal * 100) / 100;
            let discount = Math.round(product.discount_value * 100) / 100;
            amount = Math.round( (subtotal - discount) * 100) / 100;

            return amount;
        },
        searchProductInCatalogue() {
            this.run_set_catalogue([]);
            let filters = {
                category: 'Hardware',
                description: null,
                unit_id: null
            };
            this.getCatalogueToSale(filters);
        },
        customTextAccount(item) {
            let text = item.bank.name;
            return text;
        },
        customFilterAccount(item, queryText, itemText) {
            let bank_name = item.bank.name;
            let textBank = bank_name.toLowerCase();
            let textNumberAccount = item.number_account;
            let searchText = '';
            if (queryText) {
                searchText = queryText.toLowerCase();
            }

            return (
                textBank.indexOf(searchText) > -1 ||
                textNumberAccount.indexOf(searchText) > -1
            );
        },
    },
    mounted() {
        this.amount_debt = this.total;
    }

}
</script>

<style scoped>

.navigation-head-cart {
    height: 56px !important;
}

.detail-payment{
    height: 66vh;
}

.section-list-items-cart{
    /* height: 55vh; */
    overflow-y: auto;
}

.amount-missing{
    color: #F44336;
}

.amount-change{
    color: #4CAF50;
}

.btn-yape{
    background-position-y: center;
    background-image: url("~@/assets/img/yape.png");
    background-size: contain;
    background-color: #742284;
    min-width: 55px !important;
}

.item-amount{
    font-size: 1.1rem !important;
}

.label-discount{
    color: #F44336;
}

.amount-summary{
    min-height: 30px !important;
}

.list-cart-items{
    /*overflow-y: auto;*/
}

.label-amount-original {
    display: block !important;
}

.v-list-item__action-text{
    font-size: .85rem !important;
}

.label-price-discounted{
    text-decoration: line-through;
    text-decoration-color: #C62828;
}

.type-document {
    display: inline-block;
    background-color: #7ec6bd;
    border: 1px solid #7ec6bd;
    border-radius: 4px;
    width: 80px;
    text-align: center;
    color: #023145;
    margin-left: 20px;
    font-size: 1rem !important;
    font-weight: bold;
}

.label-amount-sales{
    font-size: .85rem !important;
}
</style>