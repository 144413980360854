<template>
    <v-dialog
        v-model="dialog"
        width="380"
        transition="fab-transition"
    >
        <v-card class="pl-0 pr-0">
            <v-card-title>
                <v-icon class="mr-3">fas fa-box-open</v-icon>
                <span class="description-selected">
                    {{ description }}
                </span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="pb-0">
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field v-model="form.quantity"
                                ref="quantity"
                                label="Cantidad"
                                type="number"
                                autofocus
                                outlined
                                @keydown="handleInputQuantity"
                                :rules="[
                                    () => !!form.quantity || 'Debe ingresar la cantidad',
                                    () => (!!form.quantity && parseInt(form.quantity) > 0) || 'Cantidad debe ser mayor a cero',
                                ]"
                                hide-details="auto"
                                required
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-container class="pt-0 pb-0">
                    <v-row>
                        <v-col cols="6">
                            <v-btn dark color="red" block @click="handleCancel">
                                Cancelar
                            </v-btn>
                        </v-col>
                        <v-col cols="6">
                            <v-btn dark color="green" block @click="handleOk">
                                Aceptar
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapState, mapActions} from 'vuex';

import ArrayTools from '../../helpers/ArrayTools';

export default {
    name: 'AddQuantity',
    data () {
        return {
            dialog: false,
            description: null,
            form: {
                quantity: null
            }
        }
    },
    computed: {
        ...mapState('sales', [
            'product',
            'type_operation',
            'cart'
        ]),
    },
    methods: {
        ...mapActions('sales', [
            'add_product_to_cart',
            'select_modality',
            'select_product',
            'replace_product_cart'
        ]),
        show () {
            this.clearData();
            this.description = this.product.description;
            this.form.quantity = this.product.quantity;
            this.dialog = !this.dialog;
        },
        async clearData () {
            this.description = null;
            this.form.quantity = null;
            await this.clearErrors();
        },
        handleCancel () {
            this.dialog =  false;
        },
        closeForm () {
            this.clearData();
            this.dialog = false;
        },
        handleInputQuantity (KeyboardEvent) {
            if (KeyboardEvent.code == 'Enter') {
                if (!this.existErrors()) {
                    this.addItemProductToSale();
                    this.closeForm();
                }
            }
        },
        handleOk () {
            if (!this.existErrors()) {
                this.addItemProductToSale();
                this.closeForm();
            }
        },
        addItemProductToSale () {
            this.addItemToCart();
        },
        addItemToCart () {
            let item_action = 'add';

            let productFounded = ArrayTools.existProductInCart(this.cart, this.product.product_id, this.product.type_item);

            let productToAdd = Object.assign({}, this.product);
            let id = (new Date()).getTime();
            let quantity = parseFloat(this.form.quantity);
            let discount_type = null;
            let discount_amount = 0;
            let discount_value = 0;
            
            if (productFounded && productToAdd.type_item == 'product') {
                item_action = 'replace';

                productToAdd = Object.assign({}, productFounded);

                id = productToAdd.id;
                quantity = parseFloat(this.form.quantity);

                discount_type = productToAdd.discount_type;
                discount_amount = productToAdd.discount_amount;
                discount_value = productToAdd.discount_value;
            }

            let cart_item = {
                id: id,
                inventory_id: productToAdd.inventory_id,
                category_id: productToAdd.category_id,
                category_name: productToAdd.category_name,
                product_id: productToAdd.product_id,
                description: productToAdd.description,
                sub_description: null,
                imei: null,
                number: null,
                modality: null,
                plan_id: null,
                plan_name: null,
                operator: null,
                modality_start: null,
                plan_start: null,
                modality_end: null,
                plan_id_end: null,
                plan_name_end: null,
                quantity: quantity,
                price: parseFloat(productToAdd.price),
                discount_type: discount_type,
                discount_amount: discount_amount,
                discount_value: 0,
                subtotal: quantity * parseFloat(productToAdd.price),
                commission: 0,
                type_item: productToAdd.type_item,
                is_bonus: false,
                worker_id: null,
                note: null
            };

            if (item_action === 'add') {
                this.add_product_to_cart(cart_item);
            } else {
                discount_value = this.calculateDiscountValue(cart_item);
                cart_item.discount_value = discount_value;
                this.replace_product_cart(cart_item);
            }
        },
        calculateDiscountValue (product) {
            let discount_value = 0;
            if (product.discount_type) {
                discount_value = parseFloat(product.discount_amount);
                if (product.discount_type == 'Porcentaje') {
                    let percentage = parseFloat(product.discount_amount);
                    let discount = parseFloat(product.subtotal) * (percentage / 100);
                    discount_value = Math.round(discount * 100) / 100;
                }
            }

            return discount_value;
        },
        existErrors(){
            let hasErrors = false;

            Object.keys(this.form).forEach(f => {                
                if (this.$refs[f]) {
                    if (!this.form[f]) hasErrors = true
                    this.$refs[f].validate(true);
                }                
            });

            return hasErrors;
        },
        clearErrors() {
            Object.keys(this.form).forEach(f => {
                if (this.$refs[f]) this.$refs[f].reset()                
            });
        },
    }
}
</script>

<style scoped>

</style>